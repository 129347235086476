@import "bootstrap/scss/functions";

// Primary

$color-p50: #f3f9fa;
$color-p75: #cce8e9;
$color-p100: #b7dee0;
$color-p200: #98d0d2;
$color-p300: #83c6c9; //main
$color-p400: #537c7f;
$color-p500: #50797b;

// Secondary

$color-s50: #f8eaea;
$color-s75: #e0a7aa;
$color-s100: #d48386;
$color-s200: #c14d52;
$color-s300: #b4292f; //main
$color-s400: #7e1d21;
$color-s500: #561c27;

// Ship Lap

$color-sl50: #fefefe;
$color-sl75: #fcf8ef;
$color-sl100: #faf6e9;
$color-sl200: #ecf8fc;
$color-sl300: #f7efd9; //main
$color-sl400: #ada798;
$color-sl500: #979284;
$color-sl600: #faf9f7;;

// Blue Melamine

$color-b50: #e9edef;
$color-b75: #a5b5bb;
$color-b100: #80979f;
$color-b200: #496a76;
$color-b300: #244b5a;
$color-b400: #19353f;
$color-b500: #162e37;

// Saffron

$color-sa50: #fefaef;
$color-sa75: #fbe8bf;
$color-sa100: #fadfa4;
$color-sa200: #f8d17d;
$color-sa300: #f6c862;
$color-sa400: #ac8c45;
$color-sa500: #967a3c;

// Success

$color-su50: #ebf8f2;
$color-su200: #58c390;
$color-su400: #268055;

// Error

$color-e50: #f8eaea;
$color-e300: #b4292f;
$color-e500: #6e191d;

// Warning

$color-w50: #fff7e6;
$color-w300: #ffab00;
$color-w400: #b37800;

// Neutrals

$color-n0: #fff;
$color-n10: #fcfcfc;
$color-n20: #f9f9f9;
$color-n30: #f3f3f3;
$color-n40: #ececec;
$color-n50: #dbdbdb;
$color-n70: #cacaca;
$color-n100: #b0b0b0;
$color-n300: #9f9f9f;
$color-n500: #8f8f8f;
$color-n700: #7d7d7d;
$color-n900: #6d6d6d;

// Text

$color-t0: #fff;
$color-t50: #e9e9e9;
$color-t75: #a6a6a6;
$color-t100: #818181;
$color-t200: #4b4b4b;
$color-t300: #262626;
$color-t400: #1b1b1b;
$color-t500: #171717;

// Custom

$color-scrollbar: #969593;

// Natural

$color-nsl100: #faf9f7;
$color-nsl200: #f8f6f3;
$color-nsl300: #f6f4f1;
$color-nsl35: #fbfaf9;

// Punch Card Medals

$color-bronze: #cd7f32;
$color-silver: #6d6d6d;
$color-gold: #ebb120;

$grid-breakpoints: (
    xs: 0,
    sm: 375px,
    md: 768px,
    lg: 992px,
    lge: 1024px,
    llg: 1260px,
    xl: 1440px,
    xxl: 1920px
) !default;

$container-max-widths: (
    xs: 300px,
    sm: 343px,
    md: 720px,
    lg: 940px,
    xl: 1328px,
    xxl: 1696px
) !default;

$border-radius-4: 4px !default;
$border-radius-8: 8px;

$opacity: 0.6;

// Spacing
$space-2: 2px;
$space-4: 4px;
$space-6: 6px;
$space-7: 7px;
$space-8: 8px;
$space-10: 10px;
$space-12: 12px;
$space-14: 14px;
$space-16: 16px;
$space-18: 18px;
$space-20: 20px;
$space-24: 24px;
$space-26: 26px;
$space-28: 28px;
$space-32: 32px;
$space-36: 36px;
$space-38: 38px;
$space-40: 40px;
$space-44: 44px;
$space-48: 48px;
$space-56: 56px;
$space-60: 60px;
$space-64: 64px;
$space-68: 68px;
$space-72: 72px;
$space-80: 80px;
$space-88: 88px;
$space-96: 96px;
$space-98: 98px;
$space-100: 100px;
$space-102: 102px;
$space-104: 104px;
$space-106: 106px;
$space-108: 108px;
$space-110: 110px;
$space-112: 112px;
$space-120: 120px;
$space-186: 186px;

//icons sizing
$icon-sm: 16px;
$icon-md: 24px;
$icon-lg: 32px;
$icon-xl: 44px;
$icon-xxl: 48px;

// Font Families
$font-sofia: "sofia-pro";
$font-questa: "questa-grande";

// Font sizes
$font-8: 0.5rem !default;
$font-10: 0.625rem !default;
$font-12: 0.75rem !default;
$font-13: 0.813rem !default;
$font-14: 0.875rem !default;
$font-16: 1rem !default;
$font-18: 1.125rem !default;
$font-20: 1.25rem !default;
$font-23: 1.4375rem !default;
$font-26: 1.625rem !default;
$font-29: 1.8125rem !default;
$font-32: 2rem !default;
$font-36: 2.25rem !default;
$font-46: 2.875rem !default;

// Font Weights:
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 700;
$font-bold: 800;
$font-black: 900;

// Font family
$font-family-base: $font-sofia;

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;
