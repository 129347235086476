@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.layout-wrapper {
    .layout-heading {
        color: $color-t300;
        font-size: $font-29;
        line-height: 120%;

        * {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            margin: 0;
        }
    }

    .narrow-container {
        @include media-breakpoint-up(lg) {
            max-width: 1102px;
        }
    }

    &.vert-padding {
        padding: $space-40 0;

        @include media-breakpoint-down(md) {
            padding: $space-16 0;
        }
    }

    &.layout-margin {
        margin-bottom: $space-56;
        margin-top: $space-56;

        @include media-breakpoint-up(lg) {
            margin-bottom: $space-80;
            margin-top: $space-80;
        }
    }

    .region {
        @include media-breakpoint-down(md) {
            padding-bottom: $space-8;
            padding-top: $space-8;
        }
    }

    .mobile-2r-2c {
        @include media-breakpoint-down(md) {
            .region {
                &:nth-of-type(even) {
                    padding-left: $space-8;
                }

                &:nth-of-type(odd) {
                    padding-right: $space-8;
                }
            }
        }
    }
}

.maincontent {
    .experience-component:first-child {
        .layout-wrapper {
            margin-top: 0;
        }
    }
}
